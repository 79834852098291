<template>
  <div>
       <section id="popular-courses" class="events">
          <div class="container" data-aos="fade-up">

            <div class="section-title mb-3">
              <p class="text-center">{{$t("call-for-applications")}}</p>
            </div>

            <div class="events" data-aos="zoom-in" data-aos-delay="100">
               <div class="row">
                <div class="card">
                  <div class="card-img">
                    <img src="/assets/img/banner1.png" alt="...">
                  </div>
                  <div class="card-body">
                    <div class="col-lg-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                      <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                          <p>{{$t("call-intro-p-1")}}</p>
                          <p v-html="$t('call-intro-p-2')"></p>

                           <h4 class="my-2">{{$t("award-details")}}</h4>
                           <p>{{$t("call-intro-p-3")}}</p>
                           <p>{{$t("call-intro-p-4")}}</p>
                           <ul>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-4-1")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-4-2")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-4-3")}}</ol>
                              <!-- <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-12-list-4")}}</ol> -->
                            </ul>
                            <p>{{$t("call-intro-p-4-4")}}</p>
                           <p><b>{{$t("call-intro-p-5")}}</b></p>
                           
                           <h4 class="my-2">{{$t("language")}}</h4>
                           <p>{{$t("call-intro-p-6")}}</p>
                           
                           <h4 class="my-2">{{$t("eligibility-and-entry-requirement")}}</h4>
                           <p v-html="`${$t('call-intro-p-7', {link : `/about#requirements`})}`"></p>
                      

                           <h4 class="my-2">{{$t("application-requirements")}}</h4>
                           <p v-html="`${$t('call-intro-p-8', {link : `/register`})}`"></p>
                            <p v-html="`${$t('call-intro-p-8-1', {link : `/documents`})}`"></p>

                          <!-- <h4 class="my-2">{{$t("selection-process")}}</h4>
                          <p v-html="`${$t('call-intro-p-9')}`"></p>
                          <p v-html="`${$t('call-intro-p-10', {link : `/criteria`})}`"></p> -->

                            <!-- <h4 class="my-2">{{$t("call-intro-p-11")}}</h4>
                            <p>{{$t("call-intro-p-12")}}</p>
                            <ul>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-12-list-1")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-12-list-2")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-12-list-3")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-12-list-4")}}</ol>
                            </ul> -->

                            
                            <!-- <p>{{$t("call-intro-p-13-1")}} <a :href="`/register`">{{$t("call-intro-p-13-2")}}</a> {{$t("call-intro-p-13-3")}}</p> -->

                            <!-- <h4 class="my-2">{{$t("call-intro-p-14")}}</h4>
                            <p>{{$t("call-intro-p-15")}}</p>
                            <ul>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-15-list-1")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-15-list-2")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-15-list-3")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-15-list-4")}}</ol>
                              <ol><i class="bi bi-check-circle"></i> {{$t("call-intro-p-15-list-5")}}</ol>
                            </ul> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
            </div>

          </div>
        </section>
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>